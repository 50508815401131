import * as React from 'react';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import {
  AddToCartModal,
  FlexCol,
  FlexRow,
  FlexRowCenter,
  FlexRowCenterVertical,
  prop,
  ZemplinTitle,
  ZemplinSelect as Select,
  TextP,
} from 'eshop-defaults';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { withRouter } from 'react-router';
import API from '../../services/API';
import { langSelector } from '../App/selectors';
import { Button } from 'eshop-defaults/lib/components/Zemplin/General/Button';
import { Loader } from 'eshop-defaults/lib/components/Zemplin/MyAccount/RequestDetail';
import {
  addItemToCartFromModal,
  addToCartModalProductSelector,
  addToCartModalVisibleSelector,
  cartDataSelector,
  setAddToCartModalVisibility,
} from '../Cart/cartSlice';

interface Props {
  id?: string;
  name?: string;
  label?: string;
  value: string | number | undefined;
  maxWidth?: number;
  options: Array<{
    name: string;
    value: string;
  }>;
  autoComplete?: string;
  defaultValue?: {
    name: string;
    value: string | undefined | number;
  };
  onChange: (e: any) => void;
  onBlur?: (e?: any) => void;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}

const NormsSelect = ({
  id,
  name,
  label,
  value,
  maxWidth,
  options,
  autoComplete,
  defaultValue,
  onChange,
  onBlur,
  placeholder,
  required,
  disabled,
}: Props) => {
  return (
    <Wrapper>
      {label && (
        <SelectLabel label={label}>
          {label}
          {required && <Required>{' *'}</Required>}
        </SelectLabel>
      )}
      <StyledSelect
        disabled={disabled}
        id={id ? id : ''}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
      >
        {defaultValue && (
          <option value={defaultValue!.value}>{defaultValue!.name}</option>
        )}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </StyledSelect>
    </Wrapper>
  );
};

const Wrapper = styled(FlexCol)<{
  marginRight: number;
  maxWidth: number;
  marginBottom: number;
  mobileMarginBottom: number;
  mobileMarginRight: number;
  tabletMarginRight: number;
  tabletMarginBottom: number;
}>`
  width: 100%;
  color: #aaaaaa;
  position: relative;
  max-width: ${({ maxWidth }) => rem(maxWidth)};
  margin-right: ${({ marginRight }) => rem(marginRight)};
  margin-bottom: ${({ marginBottom }) => rem(marginBottom)};
  text-align: left;

  &:focus-within {
    color: ${props => props.theme.colors.btnPrimary};
  }

  ${({ theme }) => theme.mediab.l925`
    margin: 0;
    margin-right: ${({ tabletMarginRight }) => rem(tabletMarginRight)};
    margin-bottom:  ${({ tabletMarginBottom }) => rem(tabletMarginBottom)};
  `}
  
  ${({ theme }) => theme.mediab.s450`
    margin: 0;
    margin-right: ${({ mobileMarginRight }) => rem(mobileMarginRight)};
    margin-bottom:  ${({ mobileMarginBottom }) => rem(mobileMarginBottom)};
  `}

  ${({ theme }) => theme.mediab.s450`
    max-width: initial;
  `}
`;

const SelectLabel: any = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  line-height: ${rem(20)};
  margin-bottom: ${rem(2)};
`;

const DropdownIcon = styled(FlexRowCenter)`
  width: ${rem(8)};
  height: ${rem(4)};
  position: absolute;
  right: ${rem(16)};
  bottom: ${rem(20)};
  paddding-right: ${rem(8)};
`;

const StyledSelect = styled.select`
  width: 100%;
  cursor: pointer;
  height: ${rem(48)};
  background: transparent;
  border: ${({ theme }) => theme.borders.secondary};
  border-radius: ${rem(2)};
  color: ${props => props.theme.colors.textPrimary};
  font-size: ${rem(14)};
  padding: ${rem(8)} ${rem(32)} ${rem(8)} ${rem(16)};
  appearance: none;
  box-shadow: ${({ theme }) => theme.boxShadow.input};
  font-weight: 400;
  display: flex;
  align-items: center;

  background-image: url('/images/arrow-triangle.svg');
  background-position: 95% 50%;
  background-size: 8px 4px;
  background-repeat: no-repeat;

  & > option[value=''][disabled] {
    display: none;
  }

  &:invalid {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

const Required = styled.span`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.stockCountUnavailable};
`;

export default NormsSelect;
