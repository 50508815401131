import * as React from 'react';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import {
  FlexCol,
  prop,
  ZemplinTitle,
  ZemplinSelect as Select,
  StyledLink,
} from 'eshop-defaults';
import { Link, withRouter } from 'react-router';
import API from '../../services/API';
import NormsSelect from './NormsSelect';

const Norms = () => {
  const [din, setDin] = React.useState('');
  const [stn, setStn] = React.useState('');
  const [iso, setIso] = React.useState('');
  const [uni, setUni] = React.useState('');
  const [desc, setDesc] = React.useState('');

  const [normsData, setNormsData] = React.useState<any[]>([]);

  React.useEffect(() => {
    async function fetchData() {
      const fetchedNormsData = await API.loadNorms();
      if (fetchedNormsData) {
        setNormsData(prop(fetchedNormsData, 'norms'));
      }
    }

    fetchData();
  }, []);

  const dins: string[] = ['-'];
  const stns: string[] = ['-'];
  const isos: string[] = ['-'];
  const unis: string[] = ['-'];
  const descs: string[] = ['-'];
  if (normsData && normsData.length) {
    normsData.forEach((n: any) => {
      if (n.din && !dins.includes(n.din)) {
        dins.push(n.din);
      }
    });
    normsData.forEach((n: any) => {
      if (n.stn && !stns.includes(n.stn)) {
        stns.push(n.stn);
      }
    });
    normsData.forEach((n: any) => {
      if (n.iso && !isos.includes(n.iso)) {
        isos.push(n.iso);
      }
    });
    normsData.forEach((n: any) => {
      if (n.uni && !unis.includes(n.uni)) {
        unis.push(n.uni);
      }
    });
    normsData.forEach((n: any) => {
      if (n.description && !descs.includes(n.description)) {
        descs.push(n.description);
      }
    });
  }

  const handleDinChange = (e: any) => {
    const value = e.target.value;
    setDin(value);
    const foundNorm = normsData.find(n => n.din === value);
    if (foundNorm) {
      setStn(foundNorm.stn || '-');
      setIso(foundNorm.iso || '-');
      setUni(foundNorm.uni || '-');
      setDesc(foundNorm.description || '-');
    }
  };

  const handleStnChange = (e: any) => {
    const value = e.target.value;
    setStn(value);
    const foundNorm = normsData.find(n => n.stn === value);
    if (foundNorm) {
      setDin(foundNorm.din || '-');
      setIso(foundNorm.iso || '-');
      setUni(foundNorm.uni || '-');
      setDesc(foundNorm.description || '-');
    }
  };

  const handleIsoChange = (e: any) => {
    const value = e.target.value;
    setIso(value);
    const foundNorm = normsData.find(n => n.iso === value);
    if (foundNorm) {
      setDin(foundNorm.din || '-');
      setStn(foundNorm.stn || '-');
      setUni(foundNorm.uni || '-');
      setDesc(foundNorm.description || '-');
    }
  };

  const handleUniChange = (e: any) => {
    const value = e.target.value;
    setUni(value);
    const foundNorm = normsData.find(n => n.uni === value);
    if (foundNorm) {
      setDin(foundNorm.din || '-');
      setStn(foundNorm.stn || '-');
      setIso(foundNorm.iso || '-');
      setDesc(foundNorm.description || '-');
    }
  };

  const handleDescChange = (e: any) => {
    const value = e.target.value;
    setDesc(value);
    const foundNorm = normsData.find(n => n.description === value);
    if (foundNorm) {
      setDin(foundNorm.din || '-');
      setStn(foundNorm.stn || '-');
      setIso(foundNorm.iso || '-');
      setUni(foundNorm.uni || '-');
    }
  };

  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags
          canonicalUrl={`${process.env.REACT_APP_BASE_URL}/prevodnik-noriem`}
          tags={{ title: __('Prevodník noriem') }}
        />
        <StyledZemplinTitle>{__('Prevodník noriem')}</StyledZemplinTitle>
        <StyledGridWrapper>
          <NormsSelect
            label={__('DIN')}
            options={dins.map(d => {
              return { value: d, name: d };
            })}
            name="din"
            onChange={handleDinChange}
            value={din}
          />
          <NormsSelect
            label={__('STN')}
            options={stns.map(d => {
              return { value: d, name: d };
            })}
            name="stn"
            onChange={handleStnChange}
            value={stn}
          />
          <NormsSelect
            label={__('ISO')}
            options={isos.map(d => {
              return { value: d, name: d };
            })}
            name="iso"
            onChange={handleIsoChange}
            value={iso}
          />
          <NormsSelect
            label={__('UNI')}
            options={unis.map(d => {
              return { value: d, name: d };
            })}
            name="uni"
            onChange={handleUniChange}
            value={uni}
          />
        </StyledGridWrapper>
        <br />
        <NormsSelect
          label={__('Popis')}
          options={descs.map(d => {
            return { value: d, name: d };
          })}
          name="description"
          onChange={handleDescChange}
          value={desc}
        />
        <TableLink to={'/prevodna-tabulka'}>
          {__('Zobraziť všetky hodnoty')}
        </TableLink>
      </Wrapper>
    </>
  );
};

const TableLink = styled(StyledLink)`
  margin-top: ${rem(24)};
`;

const StyledGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: ${rem(16)};
  text-align: left;

  ${({ theme }) => theme.mediab.l1050`
      grid-template-columns: repeat(1, 1fr);
  `}
`;

export const StyledZemplinTitle = styled(ZemplinTitle)`
  margin: 0 0 ${rem(24)};
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: 700;

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0 margin-bottom: ${rem(16)};
    font-size: ${rem(24)};
    line-height:${rem(32)};
  `}
`;

const Wrapper = styled(FlexCol)`
  width: 100%;
  padding-top: ${rem(40)};
  margin-bottom: ${rem(80)} !important;
  max-width: ${rem(800)};
  text-align: center;

  ${({ theme }) => theme.mediab.l925`
      padding-top: ${rem(24)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

export default Norms;
